import { Formik, useFormikContext } from "formik";
import { useState, useEffect, useRef } from "react";
import DropDown from "../ui/form-elements/DropDown";
import Label from "../ui/form-elements/Label";
import FormObserver from "../util/FormObserver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import StopTimes from "./StopTimes";

const getRoutes = async (route) => {
  const request = await fetch(
    `https://roamtransit.com/GTFS/populate_active_routes.php`
  );

  const routes = await request.json();
  return routes;
};

const getDirections = async (route) => {
  const request = await fetch(
    `https://roamtransit.com/GTFS/populate_directions.php?route=${route}`
  );

  const directions = await request.json();
  return directions;
};

const getStops = async (route, direction) => {
  const request = await fetch(
    `https://roamtransit.com/GTFS/populate_stops.php?route=${route}&direction=${direction}`
  );

  const stops = await request.json();
  return stops;
};

const getStopTimes = async (route, direction, stopId) => {
  const request = await fetch(
    `https://roamtransit.com/GTFS/populate_stop_times.php?route=${route}&direction=${direction}&stop_id=${stopId}`
  );

  const stopTimes = await request.json();
  return stopTimes;
};

function QuickView(props) {
  const [directions, setDirections] = useState([]);
  const [stops, setStops] = useState([]);
  const [stopTimes, setStopTimes] = useState([]);
  const [resetDirection, setResetDirection] = useState(true);
  const [resetStop, setResetStop] = useState(true);
  const [loading, setLoading] = useState(false);
  const [routeColours, setRouteColours] = useState(null);
  const [routeName, setRouteName] = useState("");
  const [stopName, setStopName] = useState("");
  const [error, setError] = useState("");
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const routes = await getRoutes();
      const mappedRoutes = routes.map((route) => {
        return { label: route.route_name, value: route.route_id };
      });

      setRoutes(mappedRoutes);
      setLoading(false);
    };

    get();
  }, []);

  const onChange = async (newValues, prevValues) => {
    if (newValues && prevValues) {
      // r o u t e  h a s  c h a n g e d
      if (newValues.route != prevValues.route) {
        setError(false);
        setStops([]);
        setStopTimes([]);
        if (newValues.route == "-1") {
          setDirections([]);
        } else {
          setResetDirection(!resetDirection);

          const newDirections = await getDirections(newValues.route);
          const mapDirections = newDirections[0].map((direction) => {
            return {
              label: direction.direction_description,
              value: direction.direction_id,
            };
          });

          setRouteColours({
            bgColor1: newDirections[1][0].bgcolor1,
            bgColor2: newDirections[1][1].bgcolor2,
            fgColor1: newDirections[1][2].bgcolor1,
            fgColor2: newDirections[1][3].bgcolor2,
          });

          //add the top item to the drop down
          mapDirections.unshift({ label: "Select", value: -1 });
          setDirections(mapDirections);
        }
      }

      // d i r e c t i o n  h a s  c h a n g e d
      else if (newValues.direction !== prevValues.direction) {
        setError(false);
        if (newValues.direction === -1) {
          setStops([]);
          setStopTimes([]);
        } else {
          try {
            setResetStop(!resetStop);
            setLoading(true);
            const newStops = await getStops(
              newValues.route,
              newValues.direction,
              newValues.stopId
            );
            const mapStops = newStops.map((stop) => {
              return {
                label: stop.stop_name,
                value: stop.stop_id,
              };
            });

            setLoading(false);

            //add the top item to the drop down
            mapStops.unshift({ label: "Select", value: -1 });
            setStops(mapStops);
          } catch (e) {
            setError("This route doesn't appear to be running today");
            setLoading(false);
          }
        }

        // s t o p  h a s  c h a n g e d
      } else if (newValues.stop !== prevValues.stop) {
        setError(false);
        if (newValues.stop === "-1") {
          setStopTimes([]);
        } else {
          setStopTimes([]);

          const newStopTimes = await getStopTimes(
            newValues.route,
            newValues.direction,
            newValues.stop
          );

          setStopTimes(newStopTimes);
        }
      }
    }
  };

  return (
    <div className="relative h-full">
      <div className="flex flex-col gap-2">
        <Formik initialValues={{}}>
          {(props) => (
            <>
              <FormObserver onChange={onChange} />
              <h2 className="mb-0 mt-0">Quick Schedule</h2>
              <p>
                Quick schedule helps you to quickly get the departure times for
                a given stop. To get started select your route below:
              </p>
              <Label>Select Route</Label>
              <DropDown
                setLabel={setRouteName}
                name="route"
                items={routes}
              ></DropDown>

              {directions.length > 0 && (
                <>
                  <Label>What Direction are you Heading?</Label>
                  <DropDown
                    reset={resetDirection}
                    items={directions}
                    name="direction"
                  ></DropDown>
                </>
              )}

              {stops.length > 0 && (
                <>
                  <Label>Which stop will you be at?</Label>
                  <DropDown
                    setLabel={setStopName}
                    reset={resetStop}
                    items={stops}
                    name="stop"
                  ></DropDown>
                </>
              )}
            </>
          )}
        </Formik>
        {error}
      </div>
      <div
        className={`absolute inset-0 bg-transWhiteLight flex flex-col justify-center items-center ${
          loading ? `block` : `hidden`
        }`}
      >
        <FontAwesomeIcon icon={faSpinner} spin size="2xl" />
      </div>
      {stopTimes.length > 0 && (
        <StopTimes
          routeColours={routeColours}
          stopTimes={stopTimes}
          setStopTimes={setStopTimes}
          setResetStop={setResetStop}
          resetStop={resetStop}
          stopName={stopName}
          routeName={routeName}
        />
      )}
    </div>
  );
}

export default QuickView;
