import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
function NavButtonMobile({ activeTab, children, setActiveTab, value }) {
  const handleClick = () => {
    if (value === activeTab) {
      setActiveTab("");
    } else {
      setActiveTab(value);
    }
  };
  return (
    <div
      className={`px-6 py-8 mb-0 text-lg relative ${
        activeTab === value ? `bg-roamOrange` : `bg-mediumGrey`
      } last:mb-0 font-bold text-xl text-center hover:cursor-pointer text-black`}
      onClick={handleClick}
    >
      <div className="absolute h-full flex flex-col justify-center top-0 left-3">
        {activeTab === value && <FontAwesomeIcon icon={faMinus} size="" />}
        {activeTab !== value && <FontAwesomeIcon icon={faPlus} size="" />}
      </div>
      {children}
    </div>
  );
}

export default NavButtonMobile;
