import Button from "../ui/form-elements/Button";
import { motion } from "framer-motion";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

function Modal({ setOpen, children }) {
  return (
    <motion.div
      key="content"
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      transition={{
        duration: 0.5,
        ease: [0.04, 0.62, 0.23, 0.98],
        delay: useCheckMobileScreen() ? 0.5 : 0,
      }}
      className="fixed inset-0 bg-transBlackDark flex flex-col justify-center items-center hover:cursor-pointer z-50"
      onClick={() => setOpen(false)}
    >
      <div className="bg-white p-6 min-w-[300px] relative m-12 max-w-5xl">
        <div className="bg-roamOrange py-3 mb-4 text-center text-white font-bold text-2xl">
          Important
        </div>
        <div>{children}</div>
      </div>
    </motion.div>
  );
}
export default Modal;
