import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faSpinner } from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/pagination";

function News({ numberOfTweets }) {
  const paginationRef = useRef(null);
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  SwiperCore.use([Autoplay]);
  useEffect(() => {
    const getNews = async () => {
      setLoading(true);
      const newsResponse = await fetch(
        `https://roamtransit.com/wp-json/wp/v2/home_page_slide`
      );

      const newsData = await newsResponse.json();

      const mappedNewsData = newsData.map((newsItem) => {
        return {
          title: newsItem.title.rendered,
          content: newsItem.content.rendered,
          link: newsItem.link,
          attachmentUrl: newsItem["_links"]["wp:attachment"][0].href,
        };
      });

      const mappedNewsDataWithImageUrl = mappedNewsData.map(
        async (mappedNewsItem) => {
          const imageUrlResponse = await fetch(mappedNewsItem.attachmentUrl);
          const imageUrlData = await imageUrlResponse.json();

          return {
            ...mappedNewsItem,
            imageUrl:
              imageUrlData[0]["media_details"].sizes.medium["source_url"],
          };
        }
      );

      const newsItems = await Promise.all(mappedNewsDataWithImageUrl);
      setNews(newsItems);
      setLoading(false);
    };

    getNews();
  }, []);

  return (
    <div
      className="bg-transWhiteDark p-4 min-h-[250px]"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <div className="relative w-full h-full">
        <div
          className={`absolute inset-0 bg-transWhiteLight flex flex-col justify-center items-center z-10 ${
            loading ? `block` : `hidden`
          }`}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="2xl" />
        </div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
            /*disableOnInteraction: false,*/
          }}
          pagination={{
            el: paginationRef.current,
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <div>
            {news &&
              news.map((newsItem) => {
                return (
                  <SwiperSlide>
                    <div
                      className="flex flex-col items-stretch gap-0 items-center hover:cursor-pointer w-full"
                      onClick={() => {
                        window.location.href = newsItem.link;
                      }}
                    >
                      {" "}
                      <div className="w-full news-container relative overflow-hidden flex">
                        <img
                          className=" w-full h-full object-cover"
                          src={newsItem.imageUrl}
                        />
                        <div
                          className={`w-full absolute bottom-0 opacity-[0.8] bg-roamOrange flex flex-col justify-center transition-transform duration-1000 h-[60px] origin-bottom ${
                            hovered ? "translate-y-[0]" : "translate-y-[100px]"
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faLink}
                            size="xl"
                            className="text-white"
                          />
                        </div>
                      </div>
                      <div>
                        <p
                          className="m-0 text-sm"
                          dangerouslySetInnerHTML={{
                            __html: newsItem.content,
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </div>
          <div className="flex justify-center gap-2" ref={paginationRef}></div>
        </Swiper>
      </div>
    </div>
  );
}
export default News;
