import { queryHelpers } from "@testing-library/react";
import React, { useState, useEffect, useStateEffect, useRef } from "react";
import TimePicker from "react-time-picker";
import { useField } from "formik";

export default function TimePickerInputNew(props) {
  const [value, setValue] = useState("");
  const [field, meta, helpers] = useField(props);

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [amPm, setAmPm] = useState("AM");

  const minutesRef = useRef();
  const hoursRef = useRef();

  useEffect(() => {
    const timeRe = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/;
    const currentTimeValue = `${hours}:${minutes} ${amPm}`;

    if (timeRe.test(currentTimeValue)) {
      helpers.setValue(currentTimeValue);
    } else {
      helpers.setValue("");
    }
  }, [hours, minutes, amPm]);

  const handleChange = (value) => {
    setValue(value);
    helpers.setValue(value);
  };

  const changeHours = (value) => {
    let computedHours;
    if (value > 23) {
      computedHours = 12;
    }

    if (value > 12 && value < 24) {
      computedHours = value - 12;
      setAmPm("PM");
    }

    if (value <= 12) {
      computedHours = value;
    }

    if (value.length >= 2) {
      minutesRef.current.focus();
    }
    setHours(computedHours);
  };

  const keyDownMinutes = (e) => {
    if (e.key === "Backspace") {
      if (e.target.value.length == 0) {
        hoursRef.current.focus();
        setMinutes("00");
      }
    }
  };

  const blurMinutes = (value) => {
    if (value.length < 2) {
      setMinutes("0" + value);
    }
  };

  const changeMinutes = (value) => {
    let computedMinutes;

    if (value.length > 2) {
      computedMinutes = value.substring(0, 2);
    } else if (value > 59) {
      computedMinutes = "59";
    } else {
      computedMinutes = value;
    }

    setMinutes(computedMinutes);
  };

  const changeAmPm = (e) => {
    if (e.key.toLowerCase() === "a") {
      setAmPm("AM");
    } else if (e.key.toLowerCase() === "p") {
      setAmPm("PM");
    } else if (e.key.toLowerCase() === "m") {
      //do nothing
    } else {
      setAmPm("");
    }
  };

  return (
    <div className="flex gap-1 items-center">
      <div>
        <input
          className="border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey text-inputTextGrey w-[40px]"
          ref={hoursRef}
          onChange={(e) => changeHours(e.target.value)}
          value={hours}
        />
      </div>
      <strong>:</strong>
      <div>
        <input
          className="border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey -inputTextGrey w-[40px]"
          ref={minutesRef}
          onKeyDown={(e) => keyDownMinutes(e)}
          onChange={(e) => changeMinutes(e.target.value)}
          onBlur={(e) => blurMinutes(e.target.value)}
          value={minutes}
        />
      </div>
      <div>
        <input
          className="border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey text-inputTextGrey w-[45px]"
          onKeyDown={(e) => changeAmPm(e)}
          value={amPm}
        />
      </div>
      {value}
    </div>
  );
}
