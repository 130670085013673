import { useState, useEffect } from "react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import DesktopLayout from "./Desktop/DesktopLayout";
import MobileLayout from "./Mobile/MobileLayout";

function Layout() {
  const isMobile = useCheckMobileScreen();

  if (isMobile === false) {
    return <DesktopLayout />;
  } else {
    return <MobileLayout />;
  }
}

export default Layout;
