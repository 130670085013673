import React from "react";
import { useState } from "react";
import Twitter from "../../panels/Twitter";
import News from "../../panels/News";

function Right() {
  const [numberOfTweets, setNumberOfTweets] = useState(1);
  return (
    <div className="w-1/3 bg-transWhiteLight p-8 self flex flex-col gap-6">
      <Twitter
        numberOfTweets={numberOfTweets}
        setNumberOfTweets={setNumberOfTweets}
      />
      {numberOfTweets == 1 && <News numberOfTweets={numberOfTweets} />}
    </div>
  );
}

export default Right;
