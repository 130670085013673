import React, { useEffect } from "react";
import { useField } from "formik";
import { useState } from "react";
import MinusIcon from "../../../images/minus.png";
import PlusIcon from "../../../images/plus.png";

function TicketQuantity({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    helpers.setValue(quantity);
  }, [quantity]);

  return (
    <div className="flex border-2 border-borderGrey border-solid px-2 py-1 outline-none bg-inputFillGrey w-full flex justify-between items-center leading-[1.25]">
      <div onClick={decreaseQuantity} className="flex flex-col justify-center">
        <img src={MinusIcon} className="w-[20px] hover:cursor-pointer" />
      </div>
      <div className="flex items-center gap-4">
        <div className="text-md">{quantity}</div>
        <div className="flex flex-col items-center">
          <div className="gap-1 flex">
            <div className="text-xs">{props.title}</div>
          </div>
          <div className="text-xxs">({props.subTitle})</div>
        </div>
      </div>
      <div onClick={increaseQuantity} className="flex flex-col justify-center">
        <img src={PlusIcon} className="w-[20px] hover:cursor-pointer" />
      </div>
    </div>
  );
}

export default TicketQuantity;
