import { Formik } from "formik";
import Label from "../ui/form-elements/Label";
import GooglePlaces from "../ui/form-elements/GooglePlaces";
import TimePickerInputNew from "../ui/form-elements/TimePickerInputNew";
import RadioButton from "../ui/form-elements/RadioButtons";
import DatePickerDialog from "../ui/form-elements/DatePicker";
import Button from "../ui/form-elements/Button";
import PlanYourTripValidation from "./validation/PlanYourTripValidation";
import ErrorMessage from "../ui/form-elements/ErrorMessage";

function PlanYourTrip() {
  return (
    <div>
      <Formik
        initialValues={{
          saddr: "",
          daddr: "",
          ttype: "arr",
          fdate: "",
          ftimeHours: "",
        }}
        validationSchema={PlanYourTripValidation}
        onSubmit={(values, actions) => {
          const redirectUrl = `https://roamtransit.com/trip-planner-results?saddr=${encodeURIComponent(
            values.saddr.description
          )}&daddr=${encodeURIComponent(
            values.daddr.description
          )}&fdate=${encodeURIComponent(
            values.fdate
          )}&ftime-hours=${encodeURIComponent(
            values.ftimeHours
          )}&ftime=${encodeURIComponent(
            values.fdate
          )}&ttype=${encodeURIComponent(
            values.ttype
          )}&s-pid=${encodeURIComponent(
            values.saddr.placeId
          )}&d-pid=${encodeURIComponent(values.daddr.placeId)}`;

          window.location.href = redirectUrl;
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <h2 className="mb-4 mt-0">Trip Planner</h2>
            <div className="flex flex-col gap-1 justify-start">
              <div>
                <Label>Departing From:</Label>
                <GooglePlaces name="saddr"></GooglePlaces>
                {props.errors.saddr && props.touched.saddr && (
                  <ErrorMessage>{props.errors?.saddr?.placeId}</ErrorMessage>
                )}
              </div>
              <div>
                <Label>Going To:</Label>
                <GooglePlaces name="daddr"></GooglePlaces>
                {props.errors?.daddr && props.touched?.daddr && (
                  <ErrorMessage>{props.errors?.daddr?.placeId}</ErrorMessage>
                )}
              </div>
              <div className="mt-2">
                <RadioButton
                  name="ttype"
                  default="arr"
                  items={[
                    { label: "Arrive By", value: "arr" },
                    { label: "Depart After", value: "dep" },
                  ]}
                ></RadioButton>
                {props.errors.ttype && props.touched.ttype && (
                  <ErrorMessage>{props.errors?.ttype}</ErrorMessage>
                )}
              </div>
              <div>
                <Label>Date:</Label>
                <DatePickerDialog name="fdate" />
                {props.errors?.fdate && props.touched?.fdate && (
                  <ErrorMessage>{props.errors?.fdate}</ErrorMessage>
                )}
              </div>
              <div>
                <Label>Time:</Label>
                <TimePickerInputNew name="ftimeHours" />
                {props.errors?.ftimeHours && props.touched?.ftimeHours && (
                  <ErrorMessage>{props.errors?.ftimeHours}</ErrorMessage>
                )}
              </div>
              <div className="pt-2">
                <Button onClick={() => props.submitForm()}>
                  Plan Your Trip
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default PlanYourTrip;
