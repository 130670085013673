import React, { ChangeEventHandler, useRef, useState } from "react";
import "react-day-picker/dist/style.css";
import { format, isValid, parse } from "date-fns";
import CalendarIcon from "../../../images/calendar.png";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";
import { useField } from "formik";

export default function DatePickerDialog(props) {
  const [field, meta, helpers] = useField(props);
  const [displayValue, setDisplayValue] = useState(null);
  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState("");
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, "y-MM-dd"));
      helpers.setValue(format(date, "y-MM-dd"));
      setDisplayValue(format(date, "E, MMM dd Y"));
      closePopper();
    } else {
      setInputValue("");
    }
  };

  const calculatedFromDate = props.fromDate
    ? new Date(props.fromDate) - -1 * 24 * 60 * 60 * 1000 //(1 day)
    : new Date();

  return (
    <div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsPopperOpen(false);
        }}
      >
        <div
          onClick={handleButtonClick}
          ref={popperRef}
          className="flex border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey w-full hover:cursor-pointer"
        >
          <div>{displayValue ? displayValue : "Select"}</div>
          <div className="ml-auto flex flex-col justify-center items-center">
            <img
              className={` transition-all duration-400 w-[22px] ${
                isPopperOpen ? `rotate-12` : `rotate-0`
              }`}
              src={CalendarIcon}
            />
          </div>
        </div>

        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className="dialog-sheet z-50"
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
        >
          {isPopperOpen && (
            <DayPicker
              fromDate={calculatedFromDate}
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              className={`border-2 border-borderGrey border-solid bg-white p-6 transition-all duration-400 origin-top z-50`}
            />
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}
