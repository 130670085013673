import { useState, useEffect } from "react";
import NavButtonMobile from "../../ui/navigation/NavButtonMobile";
import PanelMobile from "./MobilePanel";
import LiveBusLocations from "../../panels/LiveBusLocations";
import PlanYourTrip from "../../panels/PlanYourTrip";
import QuickView from "../../panels/QuickView";
import Reservations from "../../panels/Reservations";
import { AnimatePresence } from "framer-motion";
import Twitter from "../../panels/Twitter";
import News from "../../panels/News";

import MobilePanel from "../Mobile/MobilePanel";
import Panel from "../Desktop/Panel";

function MobileLayout() {
  const [activeTab, setActiveTab] = useState("");

  return (
    <div
      style={{ backgroundImage: `url("build/images/bg.jpg")` }}
      className="p-4  bg-cover flex flex-col justify-center select-none z-20"
    >
      <div className="mb-4">
        <NavButtonMobile
          setActiveTab={setActiveTab}
          value="RESERVATIONS"
          activeTab={activeTab}
        >
          Reservations
        </NavButtonMobile>
        <div className="w-full">
          <AnimatePresence>
            {activeTab === "RESERVATIONS" && (
              <PanelMobile>
                <Reservations />
              </PanelMobile>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mb-4">
        <NavButtonMobile
          setActiveTab={setActiveTab}
          value="TRIP_PLANNER"
          activeTab={activeTab}
        >
          Plan Your Trip
        </NavButtonMobile>
        <div className="w-full">
          <AnimatePresence>
            {activeTab === "TRIP_PLANNER" && (
              <PanelMobile>
                <PlanYourTrip />
              </PanelMobile>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mb-4">
        <NavButtonMobile
          setActiveTab={setActiveTab}
          value="QUICKVIEW"
          activeTab={activeTab}
        >
          Schedule QuickView
        </NavButtonMobile>
        <div className="w-full">
          <AnimatePresence>
            {activeTab === "QUICKVIEW" && (
              <PanelMobile>
                <QuickView />
              </PanelMobile>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mb-4">
        <NavButtonMobile
          setActiveTab={setActiveTab}
          value="LIVE_BUS_LOCATIONS"
          activeTab={activeTab}
        >
          Live Bus Locations
        </NavButtonMobile>
        <div className="w-full">
          <AnimatePresence>
            {activeTab === "LIVE_BUS_LOCATIONS" && (
              <PanelMobile>
                <LiveBusLocations />
              </PanelMobile>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mb-4">
        <NavButtonMobile
          setActiveTab={setActiveTab}
          value="SERVICE_INFO"
          activeTab={activeTab}
        >
          Service Info
        </NavButtonMobile>
        <div className="w-full">
          <AnimatePresence>
            {activeTab === "SERVICE_INFO" && (
              <PanelMobile>
                <Twitter />
                <News />
              </PanelMobile>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default MobileLayout;
