import { useEffect, useState } from "react";
import { Formik } from "formik";
import DropDown from "../ui/form-elements/DropDown";
import Button from "../ui/form-elements/Button";
import ProductButton from "../ui/form-elements/ProductButton";
import RadioButton from "../ui/form-elements/RadioButtons";
import DatePickerDialog from "../ui/form-elements/DatePicker";
import TicketQuantity from "../ui/form-elements/TicketQuantity";
import ReservationsSchema from "./validation/ReservationsValidation";
import Label from "../ui/form-elements/Label";
import ErrorMessage from "../ui/form-elements/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from "../layout/Modal";
import { AnimatePresence } from "framer-motion";

const formatFares = (fares) => {
  const prunedFares = fares.filter((fare) => {
    return fare.quantity > 0;
  });

  let fareString = "";

  prunedFares.forEach((fare, index) => {
    fareString += `${fare.fareCode}%3A${fare.quantity}`;
    if (index !== prunedFares.length - 1) {
      fareString += `,`;
    }
  });

  return fareString;
};

//object containing all product types and their associated properties
const products = {
  generalReservations: {
    localId: "GENERAL",
    productId: "6271735ece12fb087935c821",
    adultFareId: "607f25c41b32d605b8be376e",
    seniorFareId: "607f26a01d3f5005e343f795",
    youthFareId: "607f26b566de4305e2d4f279",
    childFareId: "607f2605bd98ce05e7c06e42",
    descriptionStrongText:
      "General Reservations - 8X or 8S service only (Banff - Lake Louise)",
    descriptionSubText:
      "One-way or return options available Parks Canada’s Moraine Lake Shuttle NOT included!",
  },
  superPasses: {
    localId: "SUPERPASS",
    productId: "64593b39b59d9c077f9bee55",
    adultFareId: "63ee96ac8e9b0d07aef1ffea",
    seniorFareId: "641a05fc6c0023055fd63f7e",
    youthFareId: "641a05c689593206a916a380",
    childFareId: "641a063dd426770559af8497",
    descriptionStrongText:
      "Super Pass Reservations - 8X or 8S service only (Banff - Lake Louise)",
    descriptionSubText:
      "Include Parks Canada Moraine Lake Connector Shuttle, allow same day travel as walk-up on all other Roam Transit services.",
  },
};

function Reservations({ className }) {
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [currentProduct, setCurrentProduct] = useState(
    products["generalReservations"]
  );

  const loadDestinations = async (origin) => {
    setLoading(true);
    const originResponse = await fetch(
      `https://roamtransit.com/betterez-feeds/destinations.php?location_id=${origin}&product_id=${currentProduct.productId}`
    );
    const destinations = await originResponse.json();

    const mappedDestinations = destinations.stations.map((destination) => {
      return {
        label: destination.name,
        value: destination["_id"],
      };
    });

    setDestinations(mappedDestinations);
    setLoading(false);
  };

  useEffect(() => {
    const fetchOrigins = async () => {
      setLoading(true);
      const originResponse = await fetch(
        `https://roamtransit.com/betterez-feeds/origins.php?product_id=${currentProduct.productId}`
      );

      const origins = await originResponse.json();

      const mappedOrigins = origins.stations.map((origin) => {
        return {
          label: origin.name,
          value: origin["_id"],
        };
      });

      setOrigins(mappedOrigins);
      setLoading(false);
    };

    setOrigins([]);
    setDestinations([]);
    fetchOrigins();
  }, [currentProduct]);

  return (
    <>
      <div className={`relative h-full ${modalOpen ? "" : className}`}>
        <h2 className="mb-4 mt-0">Reservations</h2>
        <div className="flex flex-col gap-2">
          <Formik
            validationSchema={ReservationsSchema}
            initialValues={{
              origin: "",
              destination: "",
              tripType: "roundtrip",
              departureDate: "",
              returnDate: "",
              quantityAdult: 0,
              quantitySenior: 0,
              quantityYouth: 0,
              quantityChild: 0,
            }}
            onSubmit={(values, { setSubmitting }) => {
              const fares = formatFares([
                {
                  fare: "adult",
                  quantity: values.quantityAdult,
                  fareCode: currentProduct.adultFareId,
                },
                {
                  fare: "senior",
                  quantity: values.quantitySenior,
                  fareCode: currentProduct.seniorFareId,
                },
                {
                  fare: "youth",
                  quantity: values.quantityYouth,
                  fareCode: currentProduct.youthFareId,
                },
                {
                  fare: "child",
                  quantity: values.quantityChild,
                  fareCode: currentProduct.childFareId,
                },
              ]);

              const url = `https://roamtransit.betterez.com/cart/607a075d39c0361ea1fe027a/trip-results?productFamily=reservation&productId=${
                currentProduct.productId
              }&type=${values.tripType}&channel=websales&departureDate=${
                values.departureDate
              }&from=${values.origin}&to=${values.destination}${
                values.tripType === "roundtrip"
                  ? `&returnDate=${values.returnDate}`
                  : ``
              }&fares=${fares}`;

              window.open(url);
            }}
          >
            {(props) => {
              return (
                <>
                  <div className="flex gap-2">
                    <ProductButton
                      disabled
                      rounded
                      onClick={() => {
                        setCurrentProduct(products["generalReservations"]);
                        props.setFieldValue("origin", "");
                        props.setFieldValue("destination", "");
                      }}
                      active={currentProduct.localId === "GENERAL"}
                    >
                      General <br />
                      Reservations
                    </ProductButton>
                    <ProductButton
                      disabled
                      rounded
                      onClick={() => {
                        setCurrentProduct(products["superPasses"]);
                        props.setFieldValue("origin", "");
                        props.setFieldValue("destination", "");
                      }}
                      active={currentProduct.localId === "SUPERPASS"}
                    >
                      Super Pass <br />
                      Reservations
                    </ProductButton>
                    <ProductButton
                      rounded
                      onClick={() =>
                        window.open(
                          "https://roamtransit.betterez.com/cart/607a075d39c0361ea1fe027a/change/6271735ece12fb087935c821"
                        )
                      }
                    >
                      Modify <br />
                      Booking
                    </ProductButton>
                  </div>

                  <div className="bg-inputFillGrey p-2 mt-2">
                    <strong>{currentProduct.descriptionStrongText}</strong>
                    <p className="mb-0">{currentProduct.descriptionSubText}</p>
                  </div>

                  <div>
                    <Label>Departing From:</Label>
                    <DropDown
                      name="origin"
                      items={origins}
                      onChange={loadDestinations}
                    />
                    {props.errors.origin && props.touched.origin && (
                      <ErrorMessage>{props.errors.origin}</ErrorMessage>
                    )}
                  </div>
                  <div>
                    <Label>Going To:</Label>
                    <DropDown name="destination" items={destinations} />
                    {props.errors.destination && props.touched.destination && (
                      <ErrorMessage>{props.errors.destination}</ErrorMessage>
                    )}
                  </div>
                  <div>
                    <Label>Ticket Type:</Label>
                    <RadioButton
                      name="tripType"
                      default="roundtrip"
                      items={
                        currentProduct.localId === "GENERAL"
                          ? [
                              { label: "One Way", value: "oneway" },
                              { label: "Return", value: "roundtrip" },
                            ]
                          : [{ label: "Return", value: "roundtrip" }]
                      }
                    ></RadioButton>
                  </div>
                  <div className="flex flex-col md:flex-row gap-2">
                    <div className="w-full md:w-1/2">
                      <Label>Departure Date:</Label>
                      <DatePickerDialog name="departureDate"></DatePickerDialog>
                      {props.errors.departureDate &&
                        props.touched.departureDate && (
                          <ErrorMessage>
                            {props.errors.departureDate}
                          </ErrorMessage>
                        )}
                    </div>
                    <div className="w-full md:w-1/2">
                      {props.values.tripType == "roundtrip" && (
                        <>
                          <Label>Return Date:</Label>
                          <DatePickerDialog
                            name="returnDate"
                            fromDate={props.values.departureDate}
                          ></DatePickerDialog>
                          {props.errors.returnDate &&
                            props.touched.returnDate && (
                              <ErrorMessage>
                                {props.errors.returnDate}
                              </ErrorMessage>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <Label>Who is travelling?</Label>
                    <div className="flex gap-2 mb-2">
                      <div className="w-1/2">
                        <TicketQuantity
                          name="quantityAdult"
                          title="Adult"
                          subTitle="18+"
                        ></TicketQuantity>
                      </div>
                      <div className="w-1/2">
                        <TicketQuantity
                          name="quantitySenior"
                          title="Senior"
                          subTitle="65+"
                        ></TicketQuantity>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-1/2">
                        <TicketQuantity
                          name="quantityYouth"
                          title="Youth"
                          subTitle="13-18"
                        ></TicketQuantity>
                      </div>
                      <div className="w-1/2">
                        <TicketQuantity
                          name="quantityChild"
                          title="Child"
                          subTitle="< 13"
                        ></TicketQuantity>
                      </div>
                    </div>
                    {(props.touched.quantityAdult ||
                      props.touched.quantitySenior ||
                      props.touched.youthQuantity ||
                      props.touched.quantityChild) &&
                      props.errors.ticketsApplied && (
                        <ErrorMessage>
                          {props.errors.ticketsApplied}
                        </ErrorMessage>
                      )}
                  </div>
                  <Button
                    className="self-start"
                    onClick={() => props.submitForm()}
                  >
                    Reserve Now
                  </Button>
                </>
              );
            }}
          </Formik>
        </div>
        <div
          className={`absolute inset-0 bg-transWhiteLight flex flex-col justify-center items-center ${
            loading ? `block` : `hidden`
          }`}
        >
          <FontAwesomeIcon icon={faSpinner} spin size="2xl" />
        </div>
      </div>
      <AnimatePresence>
        {modalOpen && (
          <Modal open={modalOpen} setOpen={setModalOpen}>
            <p>
              It is possible to reserve your tickets ahead of time for the
              following routes
            </p>

            <ul>
              <li>Route 8X - Lake Louise Express</li>
              <li>Route 8S - Lake Louise Scenic Summer</li>
            </ul>

            <p>
              All other routes are non-reservable and you can purchase your
              tickets from the driver, using ticket vending machines at select
              bus stop locations or by downloading Token Transit App.
            </p>

            <p>
              <strong>Super Pass Reservations</strong> allow unlimited travel on
              all Roam Transit services andinclude the Parks Canada Lake
              Connector Shuttle to Moraine Lake. This is the only ticket you can
              purchase with Roam Transit that will allow you to visit Moraine
              Lake. The Super Pass is valid for one day only and will expire at
              midnight the date of travel.
            </p>
            <p>
              <strong>General Reservations</strong> allow riders to book one-way
              and return tickets and do NOT include Parks Canada’s Moraine Lake
              Connector Shuttle.
            </p>
            <p>
              Click ‘Continue to Reserve’ to book your seat or modify your
              reservation. Choose ‘Read More’ to find out more about our
              services.
            </p>
            <div className="flex gap-4 justify-center">
              <Button
                onClick={() => {
                  setModalOpen(false);
                }}
                className="leading-none"
              >
                Continue to <br />
                Reserve
              </Button>
              <Button
                className="w-[115px] leading-none"
                onClick={() =>
                  window.open("https://roamtransit.com/reservations-2/")
                }
              >
                Read <br />
                More
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}

export default Reservations;
