import { useEffect, useState, useRef } from "react";
import { number } from "yup";
import Button from "../ui/form-elements/Button";

function Twitter({ numberOfTweets, setNumberOfTweets }) {
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    const getTweets = async () => {
      const tweetsResponse = await fetch(
        `https://roamtransit.com/dashboard-feeds/Twitter.php`
      );

      const tweetData = await tweetsResponse.json();

      setTweets(tweetData[1].wallPostsData.slice(0, numberOfTweets));
    };

    getTweets();
  }, [numberOfTweets]);

  return (
    <div className="bg-transWhiteDark p-4">
      {tweets &&
        tweets?.map((tweet) => {
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };
          const date = new Date(tweet.created_at).toLocaleDateString(
            "en-US",
            options
          );

          return (
            <div className="mb-4 last:mb-0 mr-4">
              <div className="flex flex-col">
                <div className="flex items-center gap-2 mb-2">
                  <img
                    className="rounded-full border-2 border-solid border-roamOrange"
                    src={tweet.user.profile_image_url}
                  />

                  <div className="font-bold">
                    <div className="text-roamOrange">@roamtransit</div>
                    <div className="font-medium">{date} </div>
                  </div>
                </div>
                <div className="text-sm">{tweet.full_text}</div>
              </div>
            </div>
          );
        })}
      <div className="text-center">
        <Button
          className="self-start"
          onClick={() => {
            console.log("clicked me");
            if (numberOfTweets == 1) {
              setNumberOfTweets(3);
            } else {
              setNumberOfTweets(1);
            }
          }}
        >
          {numberOfTweets === 1 ? "More" : "Less"} Tweets
        </Button>
      </div>
    </div>
  );
}
export default Twitter;
