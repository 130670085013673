import React, { useEffect } from "react";
import { useField } from "formik";
import { useState } from "react";
import ArrowDown from "../../../images/dropdown.png";
import OutsideClickHandler from "react-outside-click-handler";

function DropDown({ items, setLabel, reset, onChange, ...props }) {
  const [field, meta, helpers] = useField(props);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (reset != null) {
      helpers.setValue(-1);
    }
  }, [reset]);

  const toggleOpen = () => {
    if (items.length > 0) {
      setOpen(!open);
    } else {
      helpers.setTouched(true);
    }
  };

  const selectItem = (value, label) => {
    helpers.setValue(value);
    if (onChange) {
      onChange(value);
    }
    if (setLabel) {
      setLabel(label);
    }
  };

  const currentValue = items.filter((item) => {
    return item.value === meta.value;
  });

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        <div className="relative hover:cursor-pointer" onClick={toggleOpen}>
          <div
            className="border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey flex items-center"
            {...field}
            {...props}
          >
            {currentValue[0] ? currentValue[0].label : "Select"}
            <img
              className={`w-[20px] bg-contain bg-no-repeat ml-auto transition-all duration-500 ${
                open ? `rotate-180` : ``
              }`}
              src={ArrowDown}
            />
          </div>

          <div
            className={`duration-200 ease-in-out w-full absolute border-2 border-solid border-borderGrey bg-inputFillGrey transition-all mt-[-2px] origin-top z-50 ${
              open ? `scale-y-full opacity-100` : `scale-y-0 opacity-0`
            }`}
          >
            {items.map((item) => {
              return (
                <div
                  className="hover:bg-mediumGrey hover:cursor-pointer px-2 py-2 text-inputTextGrey"
                  onClick={() => selectItem(item.value, item.label)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
}

export default DropDown;
