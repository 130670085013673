import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const PlanYourTripSchema = Yup.object().shape({
  saddr: Yup.object(
    {
      description: Yup.string().required("An origin is required"),
      placeId: Yup.string().required("An origin is required"),
    },
    "A destination is required"
  ),
  daddr: Yup.object(
    {
      description: Yup.string().required("A destination is required"),
      placeId: Yup.string().required("A destination is required"),
    },
    "A destination is required"
  ),
  ttype: Yup.string().required("Trip type is required"),
  fdate: Yup.string().required("Please enter a valid date"),
  ftimeHours: Yup.string().required("Please enter a valid time"),
});

export default PlanYourTripSchema;
