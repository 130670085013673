import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const FormObserver = ({ onChange }) => {
  const { values } = useFormikContext();
  const [prevValues, setPrevValues] = useState();

  useEffect(() => {
    onChange(values, prevValues);
    setPrevValues(values);
  }, [values]);

  return null;
};

export default FormObserver;
