import { useRef, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useField } from "formik";

import OutsideClickHandler from "react-outside-click-handler";

export default function PlacesAutocomplete(props) {
  const [field, meta, helpers] = useField(props);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "ca" },
      fields: ["place_id", "name"],
      strictBounds: false,
    },
    debounce: 500,
  });

  const [open, setOpen] = useState(false);

  const ref = useRef();

  const handleInput = (e) => {
    // Update the keyword of the input element
    if (!open) {
      setOpen(true);
    }
    setValue(e.target.value);
    setOpen(true);
  };

  const handleClick = () => {
    if (data.length > 0) {
      setOpen(true);
    }
  };
  const handleSelect =
    ({ description, ...params }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      helpers.setValue({ description: description, placeId: params.place_id });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="p-2 hover:cursor-pointer text-md hover:bg-mediumGrey"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </div>
      );
    });

  return (
    <div ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        onClick={handleClick}
        disabled={!ready}
        placeholder="Where are you going?"
        className="border-2 border-borderGrey border-solid px-2 py-2 outline-none bg-inputFillGrey w-full text-inputTextGrey rounded-none"
      />

      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
        }}
      >
        {status === "OK" && open && (
          <div className="relative">
            <div
              className={`w-full absolute border-2 border-solid border-borderGrey bg-inputFillGrey transition-all mt-[-2px] origin-top z-50`}
            >
              {renderSuggestions()}
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}
