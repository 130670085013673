import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Panel({ children }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <motion.div
      key="content"
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      transition={{ duration: 0.0, ease: [0.04, 0.62, 0.23, 0.98] }}
      className={`p-6 h-full  bg-transWhiteDark border-2 border-borderGrey border-solid`}
    >
      {children}
    </motion.div>
  );
}

export default Panel;
