import { useState, useEffect } from "react";
import Modal from "../Modal";
import Left from "./Left";
import Right from "./Right";

function DesktopLayout() {
  const [activeTab, setActiveTab] = useState("TRIP_PLANNER");
  const [highLightReservations, setHighLightReservations] = useState(false);

  //event listener for reservations button click in header of site
  useEffect(() => {
    const focusReservations = (e, force) => {
      if (e?.target.id === "reservations_button" || force) {
        setActiveTab("RESERVATIONS");
        setHighLightReservations(true);
      }
    };

    document.addEventListener("click", (e) => focusReservations(e));

    document.addEventListener("animationend", (e) => {
      if (e.animationName === "pulse-reservations") {
        setHighLightReservations(false);
      }
    });

    if (document.URL.includes("#reservations")) {
      focusReservations(null, true);
    }

    return () => {
      document.removeEventListener("click", focusReservations);
      document.removeEventListener("load", focusReservations);
    };
  }, []);

  return (
    <div
      style={{ backgroundImage: `url("build/images/bg.jpg")` }}
      className="bg-cover flex flex-col justify-center select-none"
    >
      <div className="flex gap-8 w-full flex-col md:flex-row">
        <Left
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          highLightReservations={highLightReservations}
        />
        <Right setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
    </div>
  );
}

export default DesktopLayout;
