import React from "react";

function NavButton({ activeTab, children, setActiveTab, value }) {
  return (
    <div
      className={`px-8 py-8 mb-4 -mr-[2px] relative z-100 border-2 border-solid border-borderGrey rounded-l ${
        activeTab === value ? `bg-roamOrange border-r-0` : `bg-mediumGrey`
      } last:mb-0 font-bold text-baseline text-center hover:cursor-pointer`}
      onClick={() => setActiveTab(value)}
    >
      {children}
    </div>
  );
}

export default NavButton;
