import React from "react";

function Button({ onClick, children, rounded, className, noHover, disabled }) {
  return (
    <div
      onClick={onClick}
      className={`${className} text-center text-white bg-roamOrange px-4 py-2 inline-block border-roamOrange hover:cursor-pointer hover:border-roamOrange ${
        noHover ? `` : `hover:bg-transparent`
      } hover:text-inputTextGrey border-2 border-solid transition-all duration-500 font-bold ${
        rounded ? `rounded-md` : ``
      }`}
    >
      {children}
    </div>
  );
}

export default Button;
