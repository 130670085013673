import { useField } from "formik";
import React from "react";

function RadioButton({ items, ...props }) {
  const [field, meta, helpers] = useField(props);

  const handleClick = (value) => {
    helpers.setValue(value);
  };
  return (
    <ul className="grid w-full gap-0 md:grid-cols-2 list-none pl-0 border-2 border-borderGrey border-solid my-0">
      {items.map((item) => {
        let checked = props.default === item.value ? true : false;
        return (
          <li onClick={() => handleClick(item.value)}>
            <input
              type="radio"
              id={item.value}
              name={props.name}
              value={item.value}
              className="hidden peer"
              defaultChecked={checked}
            />
            <label
              onClick={() => handleClick(item.value)}
              htmlFor={item.value}
              className={`inline-flex items-center justify-between w-full px-2 py-2 text-black bg-inputFillGrey border border-gray-200 cursor-pointer transition-all duration-500 ${
                field.value === item.value ? "text-black bg-roamOrange" : ""
              }`}
            >
              <div className="block w-full">
                <div className="text-center w-full text-md font-bold">
                  {item.label}
                </div>
              </div>
            </label>
          </li>
        );
      })}
    </ul>
  );
}

export default RadioButton;
