import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function MobilePanel({ children }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <motion.div
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
      className={`h-full bg-lightGrey`}
    >
      <div className="p-6">{children}</div>
    </motion.div>
  );
}

export default MobilePanel;
