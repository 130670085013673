import React from "react";

function ProductButton({
  onClick,
  children,
  rounded,
  className,
  noHover,
  disabled,
  active,
}) {
  return (
    <div
      onClick={onClick}
      className={`${className} rounded-md text-center px-4 py-2 inline-block  hover:cursor-pointer hover:border-roamOrange hover:text-inputTextGrey border-2 border-solid transition-all duration-500 font-bold 
      ${
        active
          ? "bg-roamOrange border-roamOrange text-white hover:border-roamOrange hover:text-white"
          : "bg-inputFillGrey border-borderGrey text-inputGrey"
      }`}
    >
      {children}
    </div>
  );
}

export default ProductButton;
