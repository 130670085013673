import React from "react";
import * as Yup from "yup";

const ReservationsSchema = Yup.object().shape({
  origin: Yup.string().required("Please select an orgin of travel"),
  destination: Yup.string().when("origin", {
    is: undefined,
    then: (schema) => {
      return schema.required("Please select an origin first");
    },
    otherwise: (schema) => {
      return schema.required("Please select a destination");
    },
  }),

  tripType: Yup.string().required("Please select a trip type"),
  departureDate: Yup.string().required("Please enter a departure date"),
  returnDate: Yup.string().when("tripType", {
    is: "roundtrip",
    then: (schema) => schema.required("Return date is required"),
  }),

  ticketsApplied: Yup.string().test(
    "ticketsApplied",
    "Need to select at least one ticket type",
    (value, context) => {
      return (
        context.parent.quantityAdult > 0 ||
        context.parent.quantitySenior > 0 ||
        context.parent.quantityYouth > 0 ||
        context.parent.quantityChild > 0
      );
    }
  ),
});

export default ReservationsSchema;
