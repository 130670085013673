import LiveBusLocations from "../../panels/LiveBusLocations";
import PlanYourTrip from "../../panels/PlanYourTrip";
import QuickView from "../../panels/QuickView";
import Reservations from "../../panels/Reservations";
import NavButton from "../../ui/navigation/NavButton";
import Panel from "./Panel";

function Left({ setActiveTab, activeTab, highLightReservations }) {
  console.log(highLightReservations);
  return (
    <div className="w-2/3 bg-transWhiteLight p-8 flex gap-0">
      <div className="w-1/4">
        <NavButton
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          value="TRIP_PLANNER"
        >
          Plan Your Trip
        </NavButton>
        <NavButton
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          value="RESERVATIONS"
        >
          Reservations
        </NavButton>
        <NavButton
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          value="QUICKVIEW"
        >
          Schedule QuickView
        </NavButton>
        <NavButton
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          value="LIVE_BUS_LOCATIONS"
        >
          Live Bus Locations
        </NavButton>
      </div>
      <div className="w-3/4">
        {activeTab === "TRIP_PLANNER" && (
          <Panel>
            <PlanYourTrip />
          </Panel>
        )}
        {activeTab === "RESERVATIONS" && (
          <Panel>
            <Reservations
              className={highLightReservations ? "high-light-reservations" : ""}
            />
          </Panel>
        )}
        {activeTab === "QUICKVIEW" && (
          <Panel>
            <QuickView />
          </Panel>
        )}
        {activeTab === "LIVE_BUS_LOCATIONS" && (
          <Panel>
            <LiveBusLocations />
          </Panel>
        )}
      </div>
    </div>
  );
}

export default Left;
