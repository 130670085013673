import Button from "../ui/form-elements/Button";
function StopTimes({
  stopTimes,
  routeColours,
  setStopTimes,
  setResetStop,
  resetStop,
  routeName,
  stopName,
}) {
  //get next stop time
  let t = new Date();
  let currentTime = t.toLocaleString("en-GB").slice(12).substring(0, 5);

  let nextBusTime;

  for (let i = 0; i < stopTimes.length; i++) {
    if (stopTimes[i].sort_stop_time > currentTime) {
      nextBusTime = stopTimes[i].stop_time;
      break;
    }
  }

  return (
    <div className="absolute inset-0 -m-6 p-6 bg-white flex flex-col">
      <h2 className="mb-4">
        {routeName} at {stopName}
      </h2>
      <p>
        Here are the scheduled stop times for today - the next departure time is
        underlined:
      </p>
      <div className="grid grid-cols-6 ">
        {stopTimes.map((stopTime, index) => {
          return (
            <div
              className={`text-sm py-1 text-center max-h-[2em] border-b border-0 border-solid border-white  text-base ${
                nextBusTime === stopTime.stop_time ? `font-bold` : ``
              }`}
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? routeColours.bgColor1
                    : routeColours.bgColor2,
              }}
            >
              {stopTime["stop_time"]}
            </div>
          );
        })}
      </div>

      <div className="flex justify-center mt-auto">
        <Button
          onClick={() => {
            setResetStop(!resetStop);
            setStopTimes([]);
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
export default StopTimes;
