import { useEffect, useState } from "react";
import Label from "../ui/form-elements/Label";
import DropDown from "../ui/form-elements/DropDown";
import { Formik } from "formik";
import Button from "../ui/form-elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";

function LiveBusLocations() {
  const [routes, setRoutes] = useState([]);
  const [activeRoute, setActiveRoute] = useState("1");
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const fetchRoutes = async () => {
      setLoading(true);
      const response = await fetch(
        `https://roamtransit.com/dashboard-feeds/DoubleMap.php`
      );
      const routes = await response.json();
      const mappedRoutes = routes
        .map((route) => {
          return { label: route.name, value: route.name };
        })
        .filter((route) => {
          return route.label !== "Driver Break" && route.label !== "Spare";
        });

      setRoutes(mappedRoutes);
      setLoading(false);
    };

    fetchRoutes();
  }, []);

  const handleChange = (value) => {
    setActiveRoute(value);
  };

  return (
    <div className="h-full bg-cover flex flex-col gap-6 items-center relative">
      <div className=" self-start text-black">
        <h2 className="mb-4 mt-0">Live Bus Locations</h2>
        <p className="mb-0">
          Select a route below to see the live location of your bus.
        </p>
      </div>
      <div className="self-start w-full">
        {" "}
        <Label>Select route</Label>
        {routes.length > 0 && (
          <Formik initialValues={{}}>
            <DropDown
              items={routes}
              name="route"
              onChange={handleChange}
            ></DropDown>
          </Formik>
        )}
      </div>
      <div className="w-full h-[400px]">
        <iframe
          className="w-full h-full border-none"
          src={`https://roamtransit.doublemap.com/map/embed?name=${activeRoute}`}
        />
      </div>
      <div className="mt-auto pb-6">
        <Button noHover={true} onClick={() => setFullScreen(true)}>
          Full Screen
        </Button>
      </div>
      <div
        className={`absolute inset-0 bg-transWhiteLight flex flex-col justify-center items-center ${
          loading ? `block` : `hidden`
        }`}
      >
        <FontAwesomeIcon icon={faSpinner} spin size="2xl" />
      </div>
      <AnimatePresence>
        {fullScreen && (
          <motion.div
            key="content"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: { opacity: 1 },
              closed: { opacity: 0 },
            }}
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            className="fixed inset-0 bg-transBlackDark flex flex-col justify-center items-center bg-black hover:cursor-pointer z-50"
          >
            <iframe
              className="w-full h-full relative border-0"
              src={`https://roamtransit.doublemap.com/map/embed?name=${activeRoute}`}
            />
            <Button
              onClick={() => setFullScreen(!fullScreen)}
              className="bottom-10 absolute self-center hover:bg-white"
            >
              Close Full Screen
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LiveBusLocations;
